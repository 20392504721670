import axios from "axios";

const url = "https://api.peudevia.cat/api/";

const Login = async (email, password) => {
	let message;
	await axios
		.post(url + "login", {
			email: email,
			password: password,
			remember_me: true,
		})
		.then((response) => {
			if (response.status === 200) {
				console.log(response.data);
				localStorage.setItem("user", JSON.stringify(response.data));
				message = "Login amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "error";
			}
		})
		.catch((error) => {
			message = "error";
			console.log(error);
		});

	return message;
};

const getUser = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	console.log(user);
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.access_token,
		},
	};
	await axios
		.get(url + "user", config)
		.then((response) => {
			if (response.status === 200) {
				message = response.status;
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { message };
};

const logout = async () => {
	const user = JSON.parse(localStorage.getItem("user"));
	let message;
	let get_user;
	let config = {
		headers: {
			Accept: "application/json",
			Authorization: "Bearer " + user?.access_token,
		},
	};
	await axios
		.post(url + "logout", config)
		.then((response) => {
			if (response.status === 200) {
				get_user = response.data;
				message = "400";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "401";
			}
		})
		.catch((error) => {
			if (error.response.status === 401) {
				localStorage.removeItem("user");
				localStorage.setItem("isLoggedIn", false);
			}
		});
	return { get_user, message };
};

const getElements = async (element) => {
	let message;
	let result;
	let config = {
		headers: {
			"Access-Control-Allow-Origin": "*",
		},
	};
	await axios
		.get(url + element, config)
		.then((response) => {
			if (response.status === 200) {
				result = response.data.data;
				message = "Obtingut amb èxit";
			}
			if (
				response.data.status === "failed" &&
				response.data.success === undefined
			) {
				message = "No s'han trobat";
			}
		})
		.catch((error) => {
			console.log(error);
		});

	return { result, message };
};

const updateHoraris = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;

	let config = {
		headers: {
			"Content-Type": "multipart/form-data",
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.access_token,
		},
	};

	await axios.post(url + "horaris", values, config).then((response) => {
		if (response.status === 200) {
			message = "Horaris actualitzats";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al actualitzar";
		}
	});

	return message;
};

const updatePreus = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.access_token,
		},
	};

	await axios.post(url + "preus", values, config).then((response) => {
		if (response.status === 200) {
			message = "Preus actualitzats";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al actualitzar";
		}
	});

	return message;
};

const updateMatricules = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.access_token,
		},
	};

	await axios.post(url + "matricules", values, config).then((response) => {
		if (response.status === 200) {
			message = "Matricules actualitzades";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al actualitzar";
		}
	});

	return message;
};
const updateLloguers = async (values) => {
	const user = JSON.parse(localStorage.getItem("user"));

	let message;

	let config = {
		headers: {
			Accept: "application/json",
			"Access-Control-Allow-Origin": "*",
			Authorization: "Bearer " + user?.access_token,
		},
	};

	await axios.post(url + "lloguers", values, config).then((response) => {
		if (response.status === 200) {
			message = "Lloguers actualitzats";
		}
		if (
			response.data.status === "failed" &&
			response.data.success === undefined
		) {
			message = "Error al actualitzar";
		}
	});

	return message;
};
export {
	Login,
	getUser,
	logout,
	getElements,
	updateHoraris,
	updatePreus,
	updateMatricules,
	updateLloguers,
};
