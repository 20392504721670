import React from "react";
import { Navigate } from "react-router-dom";
import Landpage from "./Landpage";
import Admin from "./Admin/Admin";
import LoginView from "./Auth/LoginView";
import Politica from "./Landpage/Politica/Politica";
import Avis from "./Landpage/Politica/Avis";

const routes = (logged) => [
	{
		path: "/",
		element: <Landpage />,
	},
	{
		path: "/politica-privacitat",
		element: <Politica />,
	},
	{
		path: "/avis-legal",
		element: <Avis />,
	},
	{
		path: "/admin",
		element: logged ? <Admin /> : <Navigate to="/login" />,
	},
	{
		path: "/login",
		element: !logged ? <LoginView /> : <Navigate to="/admin" />,
	},
];

export default routes;
