import React, { useEffect, useState } from "react";
import { Grow, IconButton, ThemeProvider, Typography } from "@mui/material";
import theme from "./Theme";
import { Close } from "@mui/icons-material";
import { SnackbarProvider } from "notistack";
import { useRoutes } from "react-router";
import routes from "./routes";
import { getUser } from "./API/API";
import Loading from "./components/Loading";

function App() {
	const notistackRef = React.createRef();
	const onClickDismiss = (key) => () => {
		notistackRef.current.closeSnackbar(key);
	};
	const [logged, setLogged] = useState(false);
	const [loading, setLoading] = useState(true);
	const routing = useRoutes(routes(logged));

	useEffect(() => {
		const get = async () => {
			const userExist = localStorage.getItem("user");
			if (userExist) {
				const { message } = await getUser();
				if (message === 200) {
					setLogged(true);
					setLoading(false);
				} else {
					setLogged(false);
					setLoading(false);
				}
			} else {
				setLogged(false);
				setLoading(false);
			}
		};
		get();
		// const loggedInUser = localStorage.getItem("isLoggedIn");
		// // setUser(true);
		// console.log(user);
		// console.log(logged);
		// if (loggedInUser !== "true") {
		// 	const user = localStorage.getItem("user");
		// 	const foundUser = JSON.parse(user);
		// 	setLogged(true);
		// 	setUser(foundUser);
		// } else {
		// 	const { message } = getUser();
		// 	if (message === "401" || message === "undefined") {
		// 		localStorage.removeItem("user");
		// 		localStorage.setItem("isLoggedIn", false);
		// 		setUser("");
		// 		setLogged(false);
		// 	} else {
		// 		const foundUser = JSON.parse(loggedInUser);
		// 		setUser(foundUser);
		// 		setLogged(true);
		// 	}
		// }
	}, [routing]);
	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider
				maxSnack={3}
				anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
				TransitionComponent={Grow}
				ref={notistackRef}
				action={(key) => (
					<IconButton onClick={onClickDismiss(key)}>
						<Close style={{ color: "white" }} />
					</IconButton>
				)}
			>
				<Loading loading={loading}>{routing}</Loading>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
