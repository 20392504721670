import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";

let t = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1150,
			xl: 1920,
		},
	},
});

const theme = createTheme(t, {
	palette: {
		background: {
			color: "#D31834",
			default: "#1D1D1E",
		},
		primary: {
			main: "#D31834",
		},
		secondary: {
			main: "#E5E3DB",
			hover: "#0000003b",
		},
		danger: {
			main: colors.red[500],
			light: colors.red[300],
		},
		success: {
			main: colors.green[500],
		},
		text: {
			primary: "#424242",
			secondary: "#E5E3DB",
			danger: "#D31834",
		},
		typography: {
			fontFamily: "Ubuntu",
		},
	},
	typography: {
		h1: {
			fontSize: "1.8rem",
			fontFamily: "Montserrat",
			textTransform: "uppercase",
			color: "white",
		},
		h2: {
			fontSize: "1rem",
			fontFamily: "Montserrat",
			color: "#1D1D1E",
		},
		h3: {
			fontSize: "1rem",
			fontFamily: "Montserrat",
			color: "white",
		},
		h4: {
			fontSize: "1rem",
			fontFamily: "Montserrat",
			color: "#424242",
		},
		h5: {
			fontFamily: "Montserrat",
			fontWeight: 800,
			color: "#424242",
		},
		body1: {
			fontFamily: "Montserrat",
			color: "white",
			paddingTop: 5,
			fontSize: "1rem",
			[t.breakpoints.between("xs", "sm")]: {
				fontSize: "0.8rem",
			},
		},
		body2: {
			fontFamily: "Montserrat",
			[t.breakpoints.between("xs", "sm")]: {
				fontSize: "1rem",
			},
			fontSize: "1.3rem",
			color: "white",
		},
		caption: {
			fontFamily: "Montserrat",
			color: "white",
		},
		root: {
			"& a": {
				color: "#5e5e5e",
				textDecoration: "none",
				transition: "0.5s",
				"&:hover": {
					color: "black",
				},
			},
		},
		error: {
			fontFamily: "Montserrat",
			color: "#d32f2f",
			fontSize: "0.75rem",
		},
		title: {
			fontFamily: "Cookie ",
			fontSize: "3.5rem",
			color: "#b19975",
		},
	},
	"& a": {
		color: "black",
	},
});

export default theme;
