import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import sanefa1 from "../assets/img/sanefa1.svg";
import sanefa2 from "../assets/img/sanefa2.svg";
import PropTypes from "prop-types";

export default function Sanefa({ inv }) {
	const classes = useStyles();

	return (
		<Box position={"relative"}>
			<div className={classes.sanefa}>
				<img src={inv ? sanefa2 : sanefa1} style={{ width: "100%" }} />
			</div>
		</Box>
	);
}

Sanefa.propTypes = {
	inv: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
	sanefa: {
		position: "absolute",
		width: "100%",
		top: -150,
	},
}));
