/* eslint-disable react/no-unescaped-entities */
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import logo from "../../assets/img/Logo.svg";
import { useNavigate } from "react-router";

export default function Avis() {
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box className={classes.main}>
			<Container>
				<Box className={classes.container}>
					<Box textAlign={"center"} onClick={() => navigate("/")}>
						<img
							src={logo}
							className={classes.logo}
							style={{
								height: 200,
								transition: "0.5s",
								cursor: "pointer",
							}}
						/>
					</Box>
					<Typography variant="h1" mb={4} mt={2} textAlign="center">
						Avís Legal
					</Typography>

					<Typography>
						Amb la finalitat d'acomplir a l’article 10 de la Llei
						34/2002 de Serveis de la Societat de la Informació i del
						Comerç Electrònic, informem els usuaris de les nostres
						dades:
					</Typography>

					<Typography>Denominació Social: Peu de Via</Typography>

					<Typography>
						Domicili Social: Camí de Can Ballús 135, 08600 Berga,
						(Barcelona)
					</Typography>

					<Typography>NIF: B09802984</Typography>

					<Typography>Telèfon: 621180314</Typography>

					<Typography>E-Mail: peudevia@gmail.com</Typography>

					<Typography>Lloc Web: www.peudevia.cat</Typography>

					<Typography>
						Dades Registrals: Registre Mercantil de Barcelona
					</Typography>
				</Box>
			</Container>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	main: {
		backgroundColor: theme.palette.background.default,
	},
	container: {
		paddingTop: 100,
		paddingBottom: 250,
	},
}));
