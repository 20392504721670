import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Element } from "react-scroll";
import {
	CircularProgress,
	Grid,
	Typography,
	useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { getElements } from "../../API/API";

export default function Preus() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const [preus, setPreus] = useState([]);
	const [matricules, setMatricules] = useState([]);
	const [lloguers, setLloguers] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const get = async () => {
			const preus = await getElements("preus");
			const matricules = await getElements("matricules");
			const lloguers = await getElements("lloguers");
			setPreus(preus.result);
			setMatricules(matricules.result);
			setLloguers(lloguers.result);
			setLoading(false);
		};

		get();
	}, []);

	return (
		<Element name="Preus" className={classes.main}>
			<Box className={classes.container}>
				<Box pb={4}>
					<Typography variant="h1">Llistat de preus</Typography>
				</Box>
				{loading ? (
					<CircularProgress color="secondary" />
				) : (
					<Container maxWidth="md">
						{preus.map((item) => (
							<Data
								title={item.titol}
								key={item.id}
								subtitle={
									<Box
										display="flex"
										justifyContent="flex-end"
										alignItems="flex-end"
									>
										<Typography px={1}>
											{item.preus}{" "}
										</Typography>
									</Box>
								}
								caption={item.caption}
							/>
						))}
						<Box
							className={classes.linia}
							style={{ textAlign: "center" }}
						>
							<Typography variant="caption" textAlign="center">
								13 anys o menys només poden accedir a les
								instal·lacions amb la supervisió d’un adult
							</Typography>
						</Box>

						<Box ml={4} mt={6}>
							<Typography>MATRÍCULA</Typography>
						</Box>

						<Data
							one
							stitle={
								<Box>
									{matricules.map((item) => (
										<Typography key={item.id}>
											- {item.titol}: {item.preu}€{" "}
											{item.caption
												? "(" + item.caption + ")"
												: ""}
										</Typography>
									))}

									<Typography>
										- Tots els socis (mensual/estudiant,
										semestral o anual) tindran un 10-15% de
										descompte a botiga amb material.
									</Typography>
								</Box>
							}
						/>
						<Box className={classes.linia} />

						<Box ml={4} mt={6}>
							<Typography>MATERIAL DE LLOGUER</Typography>
						</Box>

						<Data
							one
							stitle={
								<Box>
									{lloguers.map((item) => (
										<Typography
											key={item.id}
											variant="body1"
										>
											{item.titol}: {item.preu}€{" "}
											{item.caption
												? "(" + item.caption + ")"
												: ""}
										</Typography>
									))}
								</Box>
							}
						/>
						<Box className={classes.linia} />
					</Container>
				)}
			</Box>
		</Element>
	);
}

function Data({ title, subtitle, caption, one, stitle }) {
	const classes = useStyles();
	return (
		<Grid container className={classes.row}>
			<Grid
				item
				sm={one ? 12 : 4}
				xs={12}
				justifyContent="flex-start"
				alignItems="center"
				display="flex"
			>
				<Typography variant="h3" textTransform={"uppercase"}>
					{title}
				</Typography>
				{stitle && <Typography>{stitle}</Typography>}
			</Grid>
			<Grid item sm={8} xs={12} textAlign="right">
				<Typography variant="body1">{subtitle}</Typography>
				{caption && (
					<Typography variant="caption">({caption})</Typography>
				)}
			</Grid>
		</Grid>
	);
}

Data.propTypes = {
	title: PropTypes.object,
	subtitle: PropTypes.object,
	caption: PropTypes.object,
	one: PropTypes.bool,
	stitle: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
	container: {
		// height: "100vh",
		paddingTop: 200,
		paddingBottom: 200,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		backgroundColor: theme.palette.background.default,
	},
	row: {
		borderTop: "1px solid " + theme.palette.primary.main,
		padding: 3,
		paddingLeft: 32,
		paddingRight: 32,
	},
	linia: {
		borderTop: "1px solid " + theme.palette.primary.main,
		paddingTop: 10,
	},
}));
