import { Box, Container } from "@mui/system";
import React from "react";
import logo from "../../assets/img/Logo.svg";
import bg from "../../assets/img/fons.jpg";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../components/CustomButton";
import { Link } from "react-scroll";
import { Button, useMediaQuery } from "@mui/material";
import { Link as LinkTo } from "react-router-dom";

export default function Menu() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	return (
		<Box className={classes.menu}>
			<Box
				className={classes.wrap}
				style={{ display: matches ? "flex" : "block" }}
			>
				<Box mx={2} pt={2}>
					<Link
						className={classes.button}
						to={"Horaris"}
						spy={true}
						smooth={true}
						duration={500}
						offset={0}
					>
						<CustomButton title="Horaris" />
					</Link>
				</Box>
				<Box mx={2} pt={2}>
					<Link
						className={classes.button}
						to={"Preus"}
						spy={true}
						smooth={true}
						duration={500}
						offset={0}
					>
						<CustomButton title="Preus" />
					</Link>
				</Box>
				<Box mx={2} pt={2}>
					<Link
						className={classes.button}
						to={"Contacte"}
						smooth={true}
						duration={1100}
						offset={0}
					>
						<CustomButton title="Contacta" />
					</Link>
				</Box>
				<Box mx={2} pt={2}>
					<CustomButton
						title="Instagram"
						target={"blank_"}
						href={"https://instagram.com/peudevia"}
					/>
				</Box>
				<Box mx={2} pt={2}>
					<CustomButton
						title="Ubicació"
						href={
							"https://www.google.com/maps/dir//peu+de+via/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x12a5a7665ceba8dd:0xb2dd6d1fdaeee066?sa=X&ved=2ahUKEwilubDkxvj6AhUN_4UKHeM2AWUQ9Rd6BAg0EAQ"
						}
						target="blank_"
					/>
				</Box>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	menu: {
		transition: "all 500ms",
		opacity: 1,
		position: "absolute",
		left: 0,
		right: 0,
		textAlign: "center",
		zIndex: 0,
		margin: "auto",
		bottom: 40,
	},
	wrap: {
		justifyContent: "center",
	},
}));
