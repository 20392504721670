/* eslint-disable react/no-unescaped-entities */
import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import logo from "../../assets/img/Logo.svg";
import { useNavigate } from "react-router";

export default function Politica() {
	const classes = useStyles();
	const navigate = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box className={classes.main}>
			<Container>
				<Box className={classes.container}>
					<Box textAlign={"center"} onClick={() => navigate("/")}>
						<img
							src={logo}
							className={classes.logo}
							style={{
								height: 200,
								transition: "0.5s",
								cursor: "pointer",
							}}
						/>
					</Box>
					<Typography variant="h1" mb={4} mt={2} textAlign="center">
						Política de privacitat
					</Typography>
					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						INTRODUCCIÓ
					</Typography>
					<Typography>
						La seva privacitat és important per a nosaltres, així
						que mitjançant aquest document li expliquem quines dades
						recopilem dels usuaris, com les utilitzem i per a quines
						finalitats, entre altres indicacions. Els majors de 14
						anys podran registrar-se com a usuaris sense el previ
						consentiment dels seus pares, tutors o representants
						legals. En cas de menors de 14 anys o identificats com a
						"incapaços legalment", sempre necessitarem del
						consentiment patern, o del tutor/a o representant legal.
						Per a la seva informació, mai sol·licitarem més
						informació de la que realment necessitarem per als
						serveis requerits; mai la compartirem amb tercers,
						excepte per obligació legal o amb la seva autorització
						prèvia; i mai utilitzarem les seves dades per a una
						altra finalitat no indicada prèviament. Com a usuari,
						haurà de llegir atentament aquesta Política de
						privacitat de manera regular, i sempre que introdueixi
						les seves dades personals, ja que aquest document pot
						sofrir modificacions perquè el prestador pot modificar
						qualsevol tipus d'informació que pogués aparèixer a la
						web per a complir amb la normativa o actualitzar la
						política, i sense que existeixi l'obligació de
						pre-avisar o posar en coneixement dels usuaris aquestes
						obligacions, sent suficients la publicació en el lloc
						web del prestador.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						RESPONSABLE DEL TRACTAMENT:
					</Typography>

					<Typography>
						Identitat: Peu de Via (també el prestador)
					</Typography>
					<Typography>NIF: B09802984</Typography>
					<Typography>
						Adreça postal: Camí de Can Ballús 135, 08600 Berga,
						(Barcelona)
					</Typography>
					<Typography>
						Correu electrònic: peudevia@gmail.com
					</Typography>

					<Typography>
						Peu de Via, com a responsable del Lloc web, de
						conformitat amb el que disposa el Reglament (UE)
						2016/679 de 27 d'abril de 2016 (RGPD), la Llei Orgànica
						3/2018 de 5 de desembre (LOPDGDD) i demés normativa
						legal vigent en matèria de protecció de dades personals,
						i per la Llei 34/2002, d'11 de juliol, de Serveis de la
						Societat de la Informació i del Comerç Electrònic
						(LSSICE), li informa que té implementades les mesures de
						seguretat necessàries, d'índole tècnica i
						organitzatives, per garantir i protegir la
						confidencialitat, integritat i disponibilitat de les
						dades introduïdes. MÈTODE D'OBTENCIÓ DE LES DADES i
						DADES QUE RECAPTEM: Les dades que recollim i la manera
						com les obtenim és la següent:
					</Typography>
					<Typography>
						1. mitjançant el formulari de contacte: nom, email,
						edat, telèfon,{" "}
					</Typography>
					<Typography>
						2. mitjançant el formulari de registre: nom, email,
						edat,{" "}
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						FINALITAT DEL TRACTAMENT:
					</Typography>
					<Typography>
						Les seves dades personals només s'utilitzaran per a les
						següents finalitats, prestant el seu consentiment per a
						cadascuna de les finalitats en l'apartat corresponent de
						la web:
					</Typography>
					<Typography>
						1. formulari de contacte: per respondre a les consultes
						i / o proporcionar informacions requerides per l'Usuari;
					</Typography>
					<Typography>
						2. formulari de registre: per a la creació d'un compte
						d'usuari i pugui accedir a les funcionalitats del web;{" "}
					</Typography>
					<Typography>3. Altres finalitats:</Typography>
					<Typography>
						o Realitzar les gestions comercials, administratives,
						fiscals, comptables que siguin necessàries en virtut de
						la legislació vigent;
					</Typography>
					<Typography>
						o Conèixer la seva opinió i millorar els serveis
						prestats per la web;
					</Typography>
					<Typography>
						o Realització d'anàlisi de perfils i d'usabilitat del
						web, a través de les cookies (esmentades en la política
						de cookies)
					</Typography>
					<Typography>
						o Xarxes socials: en l'apartat corresponent s'especifica
						l'ús de les xarxes socials
					</Typography>

					<Typography>
						ÚS FOTOGRAFIES: Les fotografies que estiguin penjades al
						web són propietat de Peu de Via, incloent les dels
						menors, en les quals, per a l'obtenció d'aquestes, s'ha
						obtingut el consentiment previ dels pares, tutors o
						representants legals mitjançant la signatura dels
						formularis realitzats per aquest efecte pels centres en
						els quals els menors formen part. No obstant això, els
						pares, tutors o representants dels menors, com a
						titulars de l'exercici dels drets d'aquests, i sempre
						previ requeriment formal per escrit, poden indicar la
						negativa a l'ús de la imatge del menor; en aquest cas,
						la imatge es mostrarà pixelada.
					</Typography>
					<Typography>
						Pot revocar en qualsevol moment el seu consentiment
						remetent un escrit amb l'assumpte "Baixa" a l’ EMAIL.
					</Typography>
					<Typography>
						D'acord amb la LSSICE, Peu de Via no realitza pràctiques
						de SPAM, per tant, no envia correus comercials per
						e-mail que no hagin estat prèviament sol·licitats o
						autoritzats per l'Usuari. En conseqüència, en totes les
						comunicacions que rebrà del prestador, l'usuari té la
						possibilitat de cancel·lar el seu consentiment exprés
						per rebre les nostres comunicacions.
					</Typography>
					<Typography>
						No tractarem les seves dades personals per a cap altra
						finalitat de les descrites excepte per obligació legal o
						requeriment judicial.
					</Typography>
					<Typography>
						No serà objecte de decisions basades en tractaments
						automatitzats que produeixin efectes sobre les seves
						dades.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						LEGITIMACIÓ DEL TRACTAMENT
					</Typography>
					<Typography>
						La base legal per al tractament de les dades és el seu
						consentiment atorgat en el moment de donar les seves
						dades en el formulari corresponent per dur a terme les
						finalitats, en el moment de marcatge de la casella de
						verificació.
					</Typography>
					<Typography>
						L'oferta prospectiva o comercial de productes i serveis
						està basada en el consentiment que se li sol·licita,
						sense que en cap cas la retirada d'aquest consentiment
						condicioni l'execució del contracte de subscripció.
					</Typography>
					<Typography>
						El no facilitar les dades personals sol·licitades o el
						no acceptar la present política de privacitat suposa la
						impossibilitat d'aconseguir les finalitats anteriorment
						descrites.
					</Typography>
					<Typography>
						En els casos en què hi hagi una prèvia relació
						contractual entre les parts, la legitimació per al
						desenvolupament de les obligacions administratives,
						fiscals, comptables i laborals que siguin necessàries en
						virtut de la legislació vigent serà l'existència prèvia
						de la relació comercial establerta entre les parts.
					</Typography>

					<Typography>
						TERMINI DE CONSERVACIÓ DE LES DADES:
					</Typography>
					<Typography>
						Les seves dades es conservaran mentre duri la relació
						comercial amb nosaltres o exerceix el seu dret de
						cancel·lació o oposició, o limitació al tractament.
						Tanmateix, conservarem les vostres dades en els terminis
						legalment previstos en les disposicions aplicables.
					</Typography>

					<Typography>
						DESTINATARIS DE CESSIONS O TRANSFERÈNCIES:
					</Typography>
					<Typography>
						Peu de Via no porta a terme cap cessió o comunicació de
						dades ni dins ni fora de la UE. El prestador únicament
						facilitarà informació a les forces i cossos de seguretat
						sota ordre judicial o per obligació d'una norma legal,
						sense perjudici de poder bloquejar o cancel·lar el seu
						compte si pot haver-hi indicis de la comissió d'algun
						delicte per part de l'usuari. La informació facilitada
						serà només la que disposi en aquest moment el prestador.
					</Typography>
					<Typography>
						La informació que vostè ens proporcioni tant a través
						d'aquest lloc web serà allotjada en els servidors de Peu
						de Via, contractats a l'empresa que presta el servei
						d’allotjament d ela web. El tractament de les dades de
						l'entitat es troba regulat mitjançant un contracte
						d'encarregat del tractament entre el prestador i aquesta
						empresa. En cap cas aquest encarregat del tractament
						subcontractarà serveis que comportin algun tractament de
						dades per tercers sense el nostre consentiment previ.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						DRETS DE LES PERSONES INTERESSADES:
					</Typography>
					<Typography>
						Com a usuari-interessat, pot sol·licitar l'exercici dels
						següents drets davant Peu de Via presentant un escrit a
						l'adreça postal de l'encapçalament o enviant un correu
						electrònic a peudevia@gmail.com, indicant com Assumpte:
						"PROTECCIÓ DE DADES: DRETS DELS INTERESSATS", i
						adjuntant fotocòpia del seu DNI o qualsevol mitjà anàleg
						en dret, tal com indica la llei.
					</Typography>
					<Typography>Drets:</Typography>
					<Typography>
						• Dret d'accés: permet a l'interessat conèixer i obtenir
						informació sobre les seves dades de caràcter personal
						sotmeses a tractament.
					</Typography>
					<Typography>
						• Dret de rectificació o supressió:permet corregir
						errors i modificar les dades que resultin ser inexactes
						o incompletes.
					</Typography>
					<Typography>
						• Dret de cancel·lació: permet que se suprimeixin les
						dades que siguin inadequades o excessives.
					</Typography>
					<Typography>
						• Dret d'oposició: dret de l'interessat a que no es
						dugui a terme el tractament de les seves dades de
						caràcter personal o se cessi en el mateix
					</Typography>
					<Typography>
						• Limitació del tractament: comporta el marcat de les
						dades personals conservats, amb la finalitat de limitar
						el seu futur tractament, per a l'exercici o la defensa
						de reclamacions.
					</Typography>
					<Typography>
						• Portabilitat de les dades: facilitació de les dades
						objecte de tractament a l'interessat, per tal que aquest
						pugui transmetre a un altre responsable, sense
						impediments.
					</Typography>
					<Typography>
						• Dret a no ser objecte de decisions individuals
						automatitzades (inclosa l'elaboració de perfils): dret a
						no ser objecte d'una decisió basada en el tractament
						automatitzat que produeixi efectes o afecti
						significativament.
					</Typography>
					<Typography>
						Com a usuari, té dret a retirar el consentiment donat en
						qualsevol moment. La retirada del consentiment no
						afectarà la licitud del tractament efectuat abans de la
						retirada del consentiment.
					</Typography>
					<Typography>
						També té dret a presentar una reclamació davant
						l'autoritat de control si considera que poden haver-se
						vist vulnerats els seus drets en relació a la protecció
						de les seves dades (aepd.es).
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						MESURES DE SEGURETAT:
					</Typography>
					<Typography>
						Les dades que ens faciliti es tractaran de forma
						confidencial. El prestador ha adoptat totes les mesures
						tècniques i organitzatives i tots els nivells de
						protecció necessaris per a garantir la seguretat en el
						tractament de les dades i evitar la seva alteració,
						pèrdua, robatori, tractament o accés no autoritzat,
						d'acord l'estat de la tecnologia i naturalesa de les
						dades emmagatzemades. Així mateix, es garanteix també
						que el tractament i registre en fitxers, programes,
						sistemes o equips, locals i centres compleixen amb els
						requisits i condicions d'integritat i seguretat
						establertes en la normativa vigent.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						IDIOMA
					</Typography>
					<Typography>
						L'idioma aplicable a aquesta política de privacitat és
						el català. Per tant, en cas que hi hagués alguna
						contradicció en alguna de les versions facilitades en
						altres idiomes, prevaldrà la versió en català.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						ANÀLISI DE PERFILS
					</Typography>
					<Typography>
						La informació facilitada en els diferents formularis,
						durant la interacció amb el web i qualsevol altra dada
						facilitada durant la navegació, incloent la informació
						subministrada per les galetes en relació als hàbits de
						navegació, comporta l'acceptació al tractament
						d'aquestes dades, per tal de ser segmentats o
						categoritzats amb la finalitat d'aplicar-los a
						l'activitat relacionada amb la gestió d'administració
						general, informació, comercialització d'espais
						publicitaris, elaborar perfils, anàlisi de propostes
						sol·licitades pels usuaris / clients, estudi estadístic
						dels serveis i continguts utilitzats, gustos i
						preferències, enquestes o formularis. Les dades
						utilitzades en l'elaboració d'aquests perfils no són
						dades que permetin identificar els usuaris sinó que
						només mostren en una gràfica el tipus de públic que
						interactua amb el web, edat, país, gènere, etc.
					</Typography>
					<Typography>
						L'usuari que accedeixi a aquest web, consent
						explícitament la utilització d'aquesta informació
						genèrica per a tasques d'elaboració de perfils,
						segmentació i categorització de dades per a les
						finalitats descrites en l'apartat anterior.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						XARXES SOCIALS
					</Typography>
					<Typography>
						L'informem que Peu de Via pot tenir presència en xarxes
						socials. El tractament de les dades que es dugui a terme
						de les persones que es facin seguidores a les xarxes
						socials (i / o realitzin qualsevol vincle o acció de
						connexió a través de les xarxes socials) de les pàgines
						oficials de Peu de Via es regirà per aquest apartat ,
						així com per aquelles condicions d'ús, polítiques de
						privacitat i normatives d'accés que pertanyin a la xarxa
						social que procedeixi en cada cas i acceptades
						prèviament per l'usuari.
					</Typography>
					<Typography>
						Peu de Via tractarà les seves dades amb les finalitats
						d'administrar correctament la seva presència a la xarxa
						social, informant d'activitats, productes o serveis del
						prestador, així com per a qualsevol altra finalitat que
						les normatives de les Xarxes Socials permetin.
					</Typography>
					<Typography>
						Queda prohibida la publicació de continguts:
					</Typography>
					<Typography>
						- Que siguin presumptament il·lícits per la normativa
						nacional, comunitària o internacional o que realitzin
						activitats presumptament il·lícites o contravinguin els
						principis de la bona fe.
					</Typography>
					<Typography>
						- Que atemptin contra els drets fonamentals de les
						persones, faltin a la cortesia a la xarxa, molestin o
						puguin generar opinions negatives en els nostres usuaris
						o tercers i en general qualssevol siguin els continguts
						que Peu de Via consideri inapropiats.
					</Typography>
					<Typography>
						- I en general que contravinguin els principis de
						legalitat, honradesa, responsabilitat, protecció de la
						dignitat humana, protecció de menors, protecció de
						l'ordre públic, la protecció de la vida privada, la
						protecció del consumidor i els drets de propietat
						intel·lectual i industrial.
					</Typography>
					<Typography>
						Així mateix, Peu de Via es reserva la potestat de
						retirar, sense previ avís del lloc web o de la xarxa
						social corporativa aquells continguts que es consideren
						inadequats.
					</Typography>
					<Typography>
						En tot cas, si Vè. remet informació personal a través de
						la xarxa social, Peu de Via quedarà exempt de
						responsabilitat en relació a les mesures de seguretat
						aplicables a la present plataforma i l’usuari, en cas de
						voler conèixer-les, ha de consultar les corresponents
						condicions particulars de la xarxa en qüestió.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						ENVIAMENT CV
					</Typography>
					<Typography>
						En el cas que l'usuari enviï el seu CV a través del
						nostre web, us informem que les dades aportades seran
						tractades per fer-lo partícep dels processos de selecció
						que hi pugui haver, duent a terme una anàlisi del perfil
						del sol·licitant amb l'objectiu de seleccionar el millor
						candidat per al lloc vacant del Responsable. Informem
						que aquest és l'únic procediment oficial per acceptar el
						seu currículum, de manera que no s'acceptaran els
						currículums remesos per un altre procediment diferent.
						En cas de produir-se alguna modificació en les dades, li
						preguem ens ho comuniqui per escrit el més aviat
						possible, a fi de mantenir les seves dades degudament
						actualitzades.
					</Typography>
					<Typography>
						Les dades es conservaran durant un termini màxim d'un
						any transcorregut el qual es procedirà a la supressió de
						les dades garantint un total respecte a la
						confidencialitat tant en el tractament com en la seva
						posterior destrucció. En aquest sentit, transcorregut
						l'esmentat termini, i si desitja continuar participant
						en els processos de selecció del Responsable, li preguem
						ens remeti novament el seu currículum.
					</Typography>
					<Typography>
						Les dades es podran tractar i/o comunicar a les empreses
						integrants del nostre grup durant el temps de
						conservació del seu currículum i per als mateixos fins
						abans informats.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						SUBSCRIPCIÓ AL BLOG
					</Typography>
					<Typography>
						En el cas que l'usuari es subscrigui al bloc, l'informem
						que les dades aportades seran tractades per gestionar la
						seva subscripció al bloc informatiu amb avís
						d'actualització i que es conservaran mentre hi hagi un
						interès mutu per mantenir la fi del tractament. Quan ja
						no sigui necessari per aquesta finalitat, es suprimiran
						amb mesures de seguretat adequades per garantir la
						seudonimización de les dades o destrucció total dels
						mateixos. No es comunicaran les dades a tercers, excepte
						obligació legal.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						PARTICIPACIÓ EN FÒRUMS
					</Typography>
					<Typography>
						En el cas que l'usuari vulgui participar en els fòrums
						de la web, l'informem que les dades aportades seran
						tractades per regular la participació en el fòrum.
						Qualsevol registre o transmissió de dades personals que
						l'usuari d'aquest fòrum faciliti suposa el coneixement i
						acceptació inequívoca de l'Avís Legal i la Política de
						Privacitat exposats a la pàgina web. Les dades es
						conservaran mentre hi hagi un interès mutu per mantenir
						la fi del tractament i quan ja no sigui necessari per a
						tal finalitat, es suprimiran amb mesures de seguretat
						adequades per garantir la pseudonimització de les dades
						(si escau) o destrucció total dels mateixos. Les
						publicacions es mostraran públicament als usuaris del
						fòrum en línia.
					</Typography>
					<Typography>
						Peu de Viano es fa responsable de qualsevol contingut
						publicat en el fòrum introduït per qualsevol usuari
						registrat. El contingut publicat només expressa el punt
						de vista del seu autor. L'autor del comentari accepta no
						fer servir aquest blog per publicar o enllaçar a
						continguts difamatoris, abusius, d'odi, amenaçadors,
						ofensius, abusius, spam o anàleg, de contingut per a
						adults o que infringeixin els drets d'autor o la llei i
						l'ordre públic en general. Si els comentaris pujats,
						segons el parer dels moderadors, s'entenen que poden
						perjudicar i/o vulnerar drets de tercers o la llei,
						podran ser eliminats sense explicació.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						PUBLICACIÓN DEL SEU TESTIMONI
					</Typography>
					<Typography>
						En el cas que l'usuari vulgui publicar la seva opinió a
						la web, l'informem que les dades aportades seran
						tractades per atendre les suggerències proposades,
						experiències o opinions respecte dels productes i/o
						serveis per ser publicades a la pàgina web i així poder
						ajudar a altres usuaris. Les dades es conservaran mentre
						hi hagi un interès mutu per mantenir la fi del
						tractament i quan ja no sigui necessari per a tal fi, es
						suprimiran amb mesures de seguretat adequades per
						garantir la pseudonimització de les dades o destrucció
						total dels mateixos. Els testimonis es publicaran a la
						nostra pàgina web. L'única dada personal que es
						publicarà del testimoni serà el seu nom.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						CANVIS EN LA PRESENT POLÍTICA DE PRIVACITAT:
					</Typography>
					<Typography>
						Peu de Viaes reserva el dret a modificar la present
						política per adaptar-la a novetats legislatives o
						jurisprudencials.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						REVOCABILITAT
					</Typography>
					<Typography>
						El consentiment prestat, tant pel tractament com per a
						la cessió de les dades dels interessats, és revocable en
						qualsevol moment comunicant-ho a Peu de Via en els
						termes que estableix aquesta Política per a l'exercici
						dels drets. Aquesta revocació en cap cas tindrà caràcter
						retroactiu.
					</Typography>

					<Typography variant="h5" color={"white"} mb={1} mt={2}>
						LEGISLACIÓ
					</Typography>
					<Typography>
						Amb caràcter general les relacions entre Peu de Via amb
						els Usuaris dels seus serveis telemàtics, presents en
						aquesta web, estan sotmesos a la legislació i
						jurisdicció espanyola a la qual se sotmeten expressament
						les parts, sent competents per a la resolució de tots
						els conflictes derivats o relacionats amb el seu ús els
						Jutjats i Tribunals de Barcelona.
					</Typography>
				</Box>
			</Container>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	main: {
		backgroundColor: theme.palette.background.default,
	},
	container: {
		paddingTop: 100,
		paddingBottom: 250,
	},
}));
