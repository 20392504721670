import { Box, Container } from "@mui/system";
import React, { useEffect, useState } from "react";
import logo from "../../assets/img/Logo.svg";
import bg from "../../assets/img/fons.jpg";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../components/CustomButton";
import { Element } from "react-scroll";
import { CircularProgress, Typography } from "@mui/material";
import Sanefa from "../../components/Sanefa";
import { getElements } from "../../API/API";

export default function Horaris() {
	const classes = useStyles();
	const [horaris1, setHoraris1] = useState([]);
	const [horaris2, setHoraris2] = useState([]);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const get = async () => {
			const horaris = await getElements("horaris");
			let h1 = [];
			let h2 = [];
			horaris.result?.map((item) =>
				item?.tipus === "1" ? h1.push(item) : h2.push(item)
			);
			setHoraris1(h1);
			setHoraris2(h2);
			setLoading(false);
		};

		get();
	}, []);

	return (
		<Element name="Horaris" className={classes.container}>
			<Box className={classes.wrap}>
				<Typography variant="h1">Horaris</Typography>
				<Box mt={3} mb={1} className={classes.header}>
					<Typography variant="h2">DE DILLUNS A DIVENDRES</Typography>
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					horaris1?.map((item) => (
						<Typography key={item.id} variant="body1">
							{item?.titol}
						</Typography>
					))
				)}

				<Box mt={3} mb={1} className={classes.header}>
					<Typography variant="h2">CAPS DE SETMANA</Typography>
				</Box>
				{loading ? (
					<CircularProgress />
				) : (
					horaris2?.map((item) => (
						<Typography key={item.id} variant="body1">
							{item?.titol}
						</Typography>
					))
				)}
			</Box>
		</Element>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		height: "100vh",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.background.default,
	},
	wrap: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		height: "90%",
	},
	header: {
		borderRadius: 8,
		backgroundColor: "white",
		textAlign: "center",
		padding: 10,
		paddingLeft: 20,
		paddingRight: 20,
	},
}));
