import { Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import logo from "../assets/img/Logo.svg";
import PropTypes from "prop-types";

export default function Header({ scrollY }) {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");

	return (
		<div className={classes.wrap} style={{}}>
			<img
				src={logo}
				className={classes.logo}
				style={{
					height: scrollY > 200 ? 100 : matches ? 300 : 200,
					opacity: scrollY > 200 ? 0.4 : 1,
					marginTop: scrollY > 200 ? 15 : matches ? 200 : 100,
					transition: "0.5s",
				}}
			/>
		</div>
	);
}

Header.propTypes = {
	scrollY: PropTypes.number,
};

const useStyles = makeStyles((theme) => ({
	wrap: {
		"-webkit-transition": "transform 500ms",
		// transition: "all 500ms",
		opacity: 1,
		position: "fixed",
		left: 0,
		right: 0,
		textAlign: "center",
		zIndex: 0,
		margin: "auto",
	},
}));
