import { Box } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Sanefa from "../components/Sanefa";
import theme from "../Theme";
import Contacte from "./Contacte/Contacte";
import Horaris from "./Horaris/Horaris";
import Portada from "./Portada/Portada";
import Preus from "./Preus/Preus";

export default function Landpage() {
	const [scrollY, setScrollY] = useState(window.scrollY);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	});

	const handleScroll = () => {
		setScrollY(window.scrollY);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Box style={{ backgroundColor: theme.palette.background.default }}>
			<Header scrollY={scrollY} />
			<Portada />
			<Horaris />
			<Sanefa />
			<Preus />
			<Sanefa inv />
			<Contacte />
			<Footer />
		</Box>
	);
}
