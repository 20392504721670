import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import theme from "../Theme";
import { CircularProgress } from "@mui/material";

const BootstrapButton = styled(Button)({
	boxShadow: "none",
	textTransform: "uppercase",
	fontSize: 16,
	padding: "10px 20px",
	borderRadius: 8,
	lineHeight: 1,
	color: "white",
	backgroundColor: "transparent",
	border: "1px solid white",
	fontFamily: "Montserrat",
	width: 150,
	"&:hover": {
		backgroundColor: "white",
		color: "black",
		borderColor: theme.palette.background.main,
		boxShadow: "0px 0px 5px 0px " + theme.palette.background.main,
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.palette.background.main,
		borderColor: theme.palette.background.main,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.palette.background.main,
	},
});

const BootstrapButtonDanger = styled(Button)({
	boxShadow: "none",
	textTransform: "none",
	fontSize: 16,
	padding: "10px 20px",
	borderRadius: 5,
	lineHeight: 1,
	color: "white",
	backgroundColor: theme.palette.text.danger,
	borderColor: theme.palette.text.danger,
	fontFamily: "Raleway",
	"&:hover": {
		backgroundColor: "white",
		color: theme.palette.text.danger,
		borderColor: theme.palette.text.danger,
		boxShadow: "0px 0px 5px 0px " + theme.palette.text.danger,
	},
	"&:active": {
		boxShadow: "none",
		backgroundColor: theme.palette.text.danger,
		borderColor: theme.palette.text.danger,
	},
	"&:focus": {
		boxShadow: "0 0 0 0.2rem " + theme.palette.text.danger,
	},
});

const CustomButton = ({ title, danger, loading, ...rest }) => {
	return danger ? (
		<BootstrapButtonDanger {...rest} variant="contained">
			{loading ? <CircularProgress size={18} /> : title}
		</BootstrapButtonDanger>
	) : (
		<BootstrapButton {...rest} variant="contained">
			{loading ? <CircularProgress size={18} /> : title}
		</BootstrapButton>
	);
};

CustomButton.propTypes = {
	danger: PropTypes.bool,
	loading: PropTypes.bool,
	title: PropTypes.string,
};

export default CustomButton;
