import {
	Box,
	Button,
	CircularProgress,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Container } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import {
	getElements,
	logout,
	updateHoraris,
	updateLloguers,
	updateMatricules,
	updatePreus,
} from "../API/API";
import logo from "../assets/img/Logo.svg";
import CustomButton from "../components/CustomButton";

const Admin = () => {
	const classes = useStyles();
	const [preus, setPreus] = useState([]);
	const [horaris1, setHoraris1] = useState([]);
	const [horaris2, setHoraris2] = useState([]);
	const [matricules, setMatricules] = useState([]);
	const [lloguers, setLloguers] = useState([]);
	const [loading, setLoading] = useState(true);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const horariForm = useForm();
	const preusForm = useForm();
	const matriculesForm = useForm();
	const lloguersForm = useForm();

	useEffect(() => {
		const get = async () => {
			const horaris = await getElements("horaris");
			const preus = await getElements("preus");
			const matricules = await getElements("matricules");
			const lloguers = await getElements("lloguers");
			let h1 = [];
			let h2 = [];
			horaris.result?.map((item) =>
				item?.tipus === "1" ? h1.push(item) : h2.push(item)
			);
			setHoraris1(h1);
			setHoraris2(h2);
			setPreus(preus.result);
			setMatricules(matricules.result);
			setLloguers(lloguers.result);
			setLoading(false);
		};

		get();
	}, []);

	const enviar = async (values) => {
		setLoading(true);
		const missatge = await updateHoraris(values);
		setLoading(false);
		enqueueSnackbar(missatge, {
			variant: "success",
		});
	};
	const enviarPreus = async (values) => {
		console.log(values);
		setLoading(true);
		const missatge = await updatePreus(values);
		setLoading(false);
		enqueueSnackbar(missatge, {
			variant: "success",
		});
	};
	const enviarMatricules = async (values) => {
		console.log(values);
		setLoading(true);
		const missatge = await updateMatricules(values);
		setLoading(false);
		enqueueSnackbar(missatge, {
			variant: "success",
		});
	};
	const enviarLloguers = async (values) => {
		console.log(values);
		setLoading(true);
		const missatge = await updateLloguers(values);
		setLoading(false);
		enqueueSnackbar(missatge, {
			variant: "success",
		});
	};

	const sortir = async () => {
		const { missatge } = await logout();
		navigate("/login");
	};

	return (
		<Box>
			<Box className={classes.root}>
				<Link to="/">
					<img src={logo} width={100} />
					<Typography variant="body1">
						{"Pàgina d'administració"}
					</Typography>
				</Link>
				<Box position={"absolute"} top={20} right={20}>
					<CustomButton title="Sortir" onClick={sortir} />
				</Box>
			</Box>
			<Container>
				{loading ? (
					<Box textAlign={"center"} p={7}>
						<CircularProgress color="secondary" />
					</Box>
				) : (
					<Box my={6}>
						<form
							onSubmit={horariForm.handleSubmit(enviar)}
							className={classes.block}
						>
							<Typography variant="h5" pb={2}>
								Horaris
							</Typography>
							<Grid container spacing={2} mt={2}>
								<Grid item md={6}>
									<Typography variant="h2" pb={2}>
										De dilluns a divendres
									</Typography>
									{horaris1?.map((item) => (
										<TextField
											key={item.id}
											fullWidth
											{...horariForm.register(
												String(item.id)
											)}
											defaultValue={item.titol}
										/>
									))}
								</Grid>
								<Grid item md={6}>
									<Typography variant="h2" pb={2}>
										Caps de setmana
									</Typography>
									{horaris2?.map((item) => (
										<TextField
											key={item.id}
											fullWidth
											{...horariForm.register(
												String(item.id)
											)}
											defaultValue={item.titol}
										/>
									))}
								</Grid>
							</Grid>
							<Box mt={4} textAlign="center">
								<Button
									type="submit"
									title="Guardar"
									variant="contained"
									color="primary"
								>
									Guardar
								</Button>
							</Box>
						</form>
						<form
							onSubmit={preusForm.handleSubmit(enviarPreus)}
							className={classes.block}
						>
							<Typography variant="h5" pb={2}>
								Preus
							</Typography>
							{preus?.map((item, index) => (
								<Grid
									container
									spacing={3}
									key={item.id}
									my={3}
								>
									<input
										value={item.id}
										hidden
										{...preusForm.register(
											`preus.${index}.id`
										)}
									/>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...preusForm.register(
												`preus.${index}.titol`
											)}
											defaultValue={item.titol}
											placeholder="Títol"
										/>
									</Grid>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...preusForm.register(
												`preus.${index}.preus`
											)}
											defaultValue={item.preus}
											placeholder="Preu amb text"
										/>
										<TextField
											key={item.id}
											fullWidth
											{...preusForm.register(
												`preus.${index}.caption`
											)}
											defaultValue={item.caption}
											placeholder="Lletra petita"
											size={"small"}
										/>
									</Grid>
								</Grid>
							))}
							<Box my={4} textAlign="center">
								<Button
									type="submit"
									title="Guardar"
									variant="contained"
									color="primary"
								>
									Guardar
								</Button>
							</Box>
						</form>
						<form
							onSubmit={matriculesForm.handleSubmit(
								enviarMatricules
							)}
							className={classes.block}
						>
							<Typography variant="h5" pb={2}>
								Matricules
							</Typography>
							{matricules?.map((item, index) => (
								<Grid
									container
									spacing={3}
									key={item.id}
									my={3}
								>
									<input
										value={item.id}
										hidden
										{...matriculesForm.register(
											`matricules.${index}.id`
										)}
									/>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...matriculesForm.register(
												`matricules.${index}.titol`
											)}
											defaultValue={item.titol}
											placeholder="Títol"
										/>
									</Grid>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...matriculesForm.register(
												`matricules.${index}.preu`
											)}
											defaultValue={item.preu}
											placeholder="Preu"
											type="number"
										/>
										<TextField
											key={item.id}
											fullWidth
											{...matriculesForm.register(
												`matricules.${index}.caption`
											)}
											defaultValue={item.caption}
											placeholder="Text"
											size={"small"}
										/>
									</Grid>
								</Grid>
							))}
							<Box my={4} textAlign="center">
								<Button
									type="submit"
									title="Guardar"
									variant="contained"
									color="primary"
								>
									Guardar
								</Button>
							</Box>
						</form>
						<form
							onSubmit={lloguersForm.handleSubmit(enviarLloguers)}
							className={classes.block}
						>
							<Typography variant="h5" pb={2}>
								Lloguers
							</Typography>
							{lloguers?.map((item, index) => (
								<Grid
									container
									spacing={3}
									key={item.id}
									my={3}
								>
									<input
										value={item.id}
										hidden
										{...lloguersForm.register(
											`lloguers.${index}.id`
										)}
									/>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...lloguersForm.register(
												`lloguers.${index}.titol`
											)}
											defaultValue={item.titol}
											placeholder="Títol"
										/>
									</Grid>
									<Grid item md={6}>
										<TextField
											key={item.id}
											fullWidth
											{...lloguersForm.register(
												`lloguers.${index}.preu`
											)}
											defaultValue={item.preu}
											placeholder="Preu"
											type="number"
										/>
										<TextField
											key={item.id}
											fullWidth
											{...lloguersForm.register(
												`lloguers.${index}.caption`
											)}
											defaultValue={item.caption}
											placeholder="Text"
											size={"small"}
										/>
									</Grid>
								</Grid>
							))}
							<Box my={4} textAlign="center">
								<Button
									type="submit"
									title="Guardar"
									variant="contained"
									color="primary"
								>
									Guardar
								</Button>
							</Box>
						</form>
					</Box>
				)}
			</Container>
		</Box>
	);
};

export default Admin;

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
		textAlign: "center",
	},
	block: {
		padding: 30,
		backgroundColor: "white",
		borderRadius: 20,
		marginTop: 50,
	},
}));
