import { Box, Typography, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import logo from "../assets/img/Logo.svg";
import fons_footer from "../assets/img/footer.svg";
import { Instagram, Twitter } from "@mui/icons-material";
import { useNavigate } from "react-router";
export default function Footer() {
	const classes = useStyles();
	const matches = useMediaQuery("(min-width:960px)");
	const navigate = useNavigate();

	return (
		<Box>
			<Box zIndex={0}>
				<div className={classes.sanefa}>
					<img
						src={fons_footer}
						style={{ width: "100%", zIndex: 0 }}
					/>
				</div>
			</Box>
			<Box
				className={classes.footer}
				style={{ marginTop: matches ? -100 : -10 }}
			>
				<img src={logo} className={classes.logo} />
				<Box mt={3}>
					<a
						href="https://www.instagram.com/peudevia/"
						target={"_blank"}
						rel="noreferrer"
					>
						<Instagram fontSize="large" sx={{ color: "white" }} />
					</a>
					{/* <a href="https://www.instagram.com/peudevia/" target={"_blank"} rel="noreferrer">
					<Twitter fontSize="large" sx={{ color: "white" }} />
                    </a> */}
				</Box>
				<Box
					onClick={() => navigate("/politica-privacitat")}
					style={{ cursor: "pointer" }}
				>
					<Typography>Política de privacitat</Typography>
				</Box>
				<Box
					onClick={() => navigate("/avis-legal")}
					style={{ cursor: "pointer" }}
				>
					<Typography>Avís legal</Typography>
				</Box>
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	footer: {
		backgroundColor: theme.palette.background.color,

		textAlign: "center",
		paddingBottom: 50,
	},
	sanefa: {
		width: "100%",
		zIndex: 0,
		marginTop: -200,
	},
	logo: {
		zIndex: 100,
		width: 200,
	},
}));
