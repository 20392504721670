import { TextField } from "@mui/material";
import { withStyles } from "@mui/styles";

const Input = withStyles((theme) => ({
	root: {
		"& .MuiOutlinedInput-root": {
			backgroundColor: "white",
			borderRadius: 8,

			"& fieldset": {
				borderRadius: 8,
			},
			"&.Mui-focused fieldset": {
				borderRadius: 8,
				borderColor: theme.palette.background.color,
			},
		},
		"& .MuiOutlinedInput-input:-webkit-autofill": {
			borderRadius: 8,
		},
	},
}))(TextField);

export default Input;
