import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import CustomButton from "../../components/CustomButton";
import { Element } from "react-scroll";
import { Grid, TextField, Typography } from "@mui/material";
import Input from "../../components/Input";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { useSnackbar } from "notistack";

export default function Contacte() {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const enviar = async (e) => {
		e.preventDefault();
		setLoading(true);
		emailjs
			.sendForm(
				"service_uds580z",
				"template_hr6gynn",
				e.target,
				"user_NC3PwN9O4z4TSXq14bfih"
			)
			.then(
				(result) => {
					console.log(result.text);
					setLoading(false);
					enqueueSnackbar("Missatge enviat! Gràcies", {
						variant: "success",
					});
				},
				(error) => {
					console.log(error.text);
					setLoading(false);
					enqueueSnackbar(
						"Hi ha hagut un error. Disculpa les molèsties.",
						{
							variant: "error",
						}
					);
				}
			);
		reset();
	};

	return (
		<Element name="Contacte" className={classes.main}>
			<form onSubmit={enviar}>
				<Box className={classes.container}>
					<Container maxWidth="md">
						<Typography variant="h1" my={6} textAlign={"center"}>
							CONTACTA
						</Typography>
						<Grid container spacing={3}>
							<Grid item md={6} xs={12} display="flex">
								<Input
									name="name"
									fullWidth
									placeholder="Nom *"
									{...register("name")}
									required
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<Input
									name="email"
									placeholder="E-mail *"
									fullWidth
									{...register("email")}
									required
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} mt={1}>
							<Grid item md={12} xs={12} display="flex">
								<Input
									name="assumpte"
									fullWidth
									{...register("assumpte")}
									placeholder="Assumpte *"
									required
								/>
							</Grid>
						</Grid>

						<Grid container spacing={2} mt={1}>
							<Grid item md={12} xs={12} display="flex">
								<Input
									multiline
									{...register("missatge")}
									rows={5}
									name="missatge"
									fullWidth
									placeholder="Missatge *"
									required
								/>
							</Grid>
						</Grid>
						<Box textAlign={"center"} mt={4}>
							<CustomButton
								loading={loading}
								type="submit"
								title="Enviar"
							/>
						</Box>
					</Container>
				</Box>
			</form>
		</Element>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: 100,
		paddingBottom: 250,
		height: "80vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		backgroundColor: theme.palette.background.default,
	},
}));
