import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useForm } from "react-hook-form";
import { Login } from "../API/API";
import theme from "../Theme";
import logo from "../assets/img/Logo.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.background.default,
		height: "100%",
		paddingBottom: theme.spacing(3),
		paddingTop: theme.spacing(3),
	},
}));

const LoginView = () => {
	const classes = useStyles();
	const [error, setError] = useState("");
	let navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onTouched",
	});

	const logInEmail = async (values) => {
		const { email, password } = values;
		const token = await Login(email, password);
		if (token === "error") {
			setError("Usuari o contrasenya incorrectes");
		} else {
			navigate("/admin/");
		}
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			py={10}
			bgcolor={theme.palette.background.default}
		>
			<Container maxWidth="sm">
				<Box textAlign={"center"} mb={5}>
					<img src={logo} width={200} />
				</Box>
				<form onSubmit={handleSubmit(logInEmail)}>
					<Box mb={3}>
						<Typography color="textPrimary" variant="h2">
							{"Entrar"}
						</Typography>
						<Typography
							color="textSecondary"
							gutterBottom
							variant="body2"
						>
							{"Entra a la pàgina d'administració"}
						</Typography>
					</Box>
					<TextField
						error={Boolean(errors && errors.email)}
						fullWidth
						helperText={errors && errors?.email?.message}
						label="E-mail"
						margin="normal"
						name="email"
						type="email"
						variant="outlined"
						{...register("email")}
						InputProps={{
							classes: {
								input: "white",
								color: "white",
							},
						}}
					/>
					<TextField
						error={Boolean(errors && errors.password)}
						fullWidth
						helperText={errors && errors?.password?.message}
						label={"Contrasenya"}
						margin="normal"
						name="password"
						type="password"
						variant="outlined"
						{...register("password")}
					/>
					<Typography variant="body1" style={{ color: "red" }}>
						{error}
					</Typography>

					<Box my={2}>
						<Button
							color="primary"
							fullWidth
							size="large"
							type="submit"
							variant="contained"
						>
							{"Entrar"}
						</Button>
					</Box>
				</form>
			</Container>
		</Box>
	);
};

export default LoginView;
