import { Box, Container } from "@mui/system";
import React from "react";
import logo from "../../assets/img/Logo.svg";
import bg from "../../assets/img/fons.jpg";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Menu from "./Menu";

export default function Portada() {
	const classes = useStyles();
	return (
		<Box
			className={classes.container}
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			<Box flex={1} alignItems="flex-end" display={"flex"} pb={5}>
				<Menu />
			</Box>
		</Box>
	);
}

const useStyles = makeStyles((theme) => ({
	container: {
		backgroundImage: `url(${bg})`,
		width: "100vw",
		height: "100vh",
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center",
		flexDirection: "column",
		backgroundSize: "cover",
	},
	logo: {
		width: 300,
	},
}));
